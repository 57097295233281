import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePathData, useTree } from '@/hooks/data/tree/useTree';
import { useUserSettings } from '@/context/UserSettingsContext';
import { useSearchQuery } from '@/hooks/useSearchQuery';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { GalleryLayout } from '@/components/gallery-layout';
import { useAssetList } from '@/hooks/data/assets/useAssetList';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { EntityType } from '@/types/entity';
import { DamLayout } from '@/components/layout';
import { useAuth0 } from '@auth0/auth0-react';
import { useSort } from '@/hooks/useSort';

export const HomeComponent = () => {
  const { isAuthenticated } = useAuth0();
  const { tree, isRootNodesLoading, includeSubFolderAssets } = useTree();
  const { assetsPerPage } = useUserSettings();
  const { query, push, replace, pathname } = useRouter();
  const { results, page } = query;

  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const folder = useTreeStore((state) => state.selectedFolder);
  const album = useTreeStore((state) => state.selectedAlbum);

  const { openIds, firstTimeLoaded, setFirstTimeLoaded, setOpenIds } = useTree();
  const { pathSlugs, uiSection } = useCurrentPage();
  const { folderIds, folderId, albumId } = usePathData(
    pathSlugs ?? [],
    firstTimeLoaded && uiSection === 'browse', // Before we can properly sort out
  );

  useEffect(() => {
    if (firstTimeLoaded && (folderIds.length > 0 || folderId || albumId)) {
      setFirstTimeLoaded(false);
      setSelectedAlbum(albumId);
      setSelectedFolder(folderId);
      setOpenIds([...openIds, ...folderIds]);
    }
  }, [folderIds, folderId, albumId]);

  const { sort, handleOnSortChange, handleOnSortOrderChange, ready: sortReady } = useSort(EntityType.Asset);
  const { queryString, debouncedSearch, showFilterInput, setShowFilterInput } = useSearchQuery();
  const { error, assets } = useAssetList(
    Number(page ?? 1),
    Number(results ?? 10),
    folder && String(folder),
    album && String(album),
    includeSubFolderAssets,
    sort,
    queryString,
    sortReady,
  );

  const setQueryParams = (value: string, order: string, qString: string) => {
    const newQuery = {
      ...query,
      page: 1,
      sortValue: value,
      sortOrder: order,
      queryString: qString,
    };

    void replace(
      {
        pathname: pathname === '/' ? '/browse' : pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    setQueryParams(sort.value, sort.order, queryString);
  }, [sort.value, sort.order, queryString]);

  useEffect(() => {
    if (isAuthenticated && !isRootNodesLoading && tree) {
      void push(
        {
          // Select first folder from the tree on initial load
          query: { page: 1, results: assetsPerPage ?? 10, ...query },
        },
        undefined,
        { shallow: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, tree]);

  return (
    <DamLayout assets={assets?.assets} showAssetSizeSlider showAssetsPerPageMenu showGalleryLayoutOptions>
      <GalleryLayout
        data={assets}
        pageTitle={'All'}
        pageDescription={'All'}
        resizeValue={0}
        sort={sort}
        onError={error}
        handleOnSortChange={handleOnSortChange}
        handleOnSortOrderChange={handleOnSortOrderChange}
        queryString={queryString}
        handleOnSearch={debouncedSearch}
        showFilterInput={showFilterInput}
        setShowFilterInput={setShowFilterInput}
      />
    </DamLayout>
  );
};
